import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import HochzeitsodyseePosts from 'components/HochzeitsodyseePosts';

const OdyseePage: React.FC = () => {
	return (
		<Layout>
			<SEO title="Hochzeitsodysee" />
			<HochzeitsodyseePosts />
		</Layout>
	);
};

export default OdyseePage;
