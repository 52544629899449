import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import { SectionTitle, ImageSharpFluid } from 'helpers/definitions';

import FormatHtml from 'components/utils/FormatHtml';

const HochzeitsodyseePosts: React.FC = () => {
	const { markdownRemark } = useStaticQuery(graphql`
		query {
			markdownRemark(frontmatter: { category: { eq: "odysee section" } }) {
				frontmatter {
					title
				}
				html
			}
		}
	`);

	const sectionTitle: SectionTitle = markdownRemark.frontmatter;

	return (
		<Container section>
			<TitleSection title={sectionTitle.title} center />
			<div>{markdownRemark.html && <FormatHtml content={markdownRemark.html} />}</div>
		</Container>
	);
};

export default HochzeitsodyseePosts;
